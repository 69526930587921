.user-list-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.user-list-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f0f0f0;
  width: 60%;
}

.search-input {
  width: auto;
  margin: 0rem 2rem;
}

.user-list-modal-content {
  max-height: 300px;
  width: 60%;
  overflow-y: auto;
  background-color: #f0f0f0;
}

.user-item {
  padding: 8px;
  cursor: pointer;
}

.user-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.user-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.user-item:last-child {
  margin-bottom: 10px;
}

/* Style for the selected user section in CCard component */
.CCard .selected-user-section {
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f0f0;
}
