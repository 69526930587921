.App {
  text-align: center;
}

.APP-COLOR { color: rgb(34, 93, 153) !important; }
.APP-COLOR-2 { color: rgb(245, 141, 93) !important; }
.APP-BACKGROUND { background-color: rgb(34, 93, 153) !important; }
.APP-BACKGROUND-2 { background-color: rgb(245, 141, 93) !important; }
.APP-BORDER { border: 1px solid rgb(245, 141, 93) !important;}
.APP-BORDER-2 { border: 1px solid rgb(34, 93, 153) !important;}
.APP-FADE-BACKGROUND { background-color: lightgray !important; }
.APP-FADE-BORDER { border: 1px solid lightgray !important}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.center {
  margin: auto;
  padding: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.h-100vh { height: 100vh !important;}

.otp-btn { padding: 10px 120px !important; }
.register-btn { padding: 10px 110px !important; }

.flex { display: flex;}
.space-between { justify-content: space-between}
.gap-3 { gap: 3}
.gap-5 { gap: 5}
/* Margin */
.m-10 { margin: 10px; }
.m-l-256px { margin-left: 256px; }
.m-l-64px { margin-left: 64px; }
.m-l-10 {margin-left: 10px;}
.m-l-15 {margin-left: 15px;}

/* Padding */
.p-l-10 { padding-left: 10px;}

/* Border */
.b-r-1px { border-right: 1px solid lightgray;}

/* color */
.green { color: green;}
.red { color: red;}
.yellow { color: yellow;}
.white { color: white !important;}

/* font size */
.fs-16 { font-size: 16px;}

/* font weight */
.fw-bold {font-weight: bold;}

.cursor { cursor: pointer;}

/* background */
.bg-green { background-color: green !important;}

.label-value-container {
  display: flex;
  align-items: center;
}

.label-box {
  padding: 8px;
  font-weight: bold;
}
.checkbox-label {
  margin-left: 8px; 
}
::-webkit-scrollbar {
  width: 0.1px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.button-cell {
  display: flex;
  gap: 10px;
}
.title-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-options-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap:10px
}

.selected-option-box {
  background-color: #f0f0f0; 
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.remove-option {
  cursor: pointer;
  color: #e53935;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  margin-left: 8px;
}

.remove-option:hover {
  text-decoration: underline;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}